<template>
    <div>
        <span class="label label-warning d-inline-block mr-2 pl-2 pr-2 mb-0">            
            <span class="mr-1">Port</span>
            <span v-for="(port, index) in checkedPort" 
                :key="port" >
                <strong>{{port}}</strong>
                <span v-if="index < checkedPort.length-1">, </span>
            </span>
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            },
        },
        watch: {
            element() {
                this.checkedPort = this.element.serviceDetails.split(',');
            }
        },
        data() {
            return {
                checkedPort: [],
            };
        },
        created() {
            this.checkedPort = this.element.serviceDetails.split(',');
        }
    }
</script>